declare var window: any

const NODE_ENV = window.config ? window.config.NODE_ENV : "pre"

export const BASE_PATH = "/trace_code_home"

export const TRACE_CODE_INTEGRATION_URL =
  NODE_ENV === "prod"
    ? "https://alihealth.taobao.com/trace_code_home"
    : "https://alihealthnew.wapa.taobao.com/trace_code_home"

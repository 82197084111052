import React, { FC } from "react"
import { Link } from "react-router-dom"
import ToolCard from "../../../../components/ToolCard"
import "./index.css"

const Footer: FC = () => {
  return (
    <div className="Footer">
      <div className="Footer-main">
        <div className="Footer-tools">
          {toolCardConfig.map(config => {
            return <ToolCard {...config} />
          })}
        </div>
        <div className="Footer-separator"></div>
        <div className="Footer-department">
          {departmentConfig1.map(config => {
            const { key, title, link, style } = config
            return (
              <div className="Footer-department_item" key={key} style={style}>
                <a href={link} target="_blank">
                  {title}
                </a>
              </div>
            )
          })}
        </div>
        <div className="Footer-department">
          {departmentConfig2.map(config => {
            const { key, title, link, style } = config
            return (
              <div className="Footer-department_item" key={key} style={style}>
                <a href={link} target="_blank">
                  {title}
                </a>
              </div>
            )
          })}
        </div>

        <div className="Footer-bottom">
          <div className="Footer-bottom_Declaration">
            <span>
              <a
                target="blank"
                href="//www.mashangfangxin.com/about/us?spm=a21f6.8078391.0.0.739e68571z80BH"
              >
                关于我们
              </a>
            </span>
            <span>
              <a
                target="blank"
                href="//www.mashangfangxin.com/law?spm=a21f6.8078384.0.0.7b796857ThsuT8"
              >
                法律声明
              </a>
            </span>
            <span>
              <a
                target="blank"
                href="//jubao.alibaba.com/internet/readme.htm?spm=a21f6.8078384.0.0.7b7968579gYwPU&site=jiankang"
              >
                廉政举报
              </a>
            </span>
          </div>
          <div className="Footer-bottom_copyright">
            Copyright©2017阿里健康版权所有 | 冀ICP备15020852-3 |
            公网安备13010402001189号
          </div>
        </div>
      </div>
    </div>
  )
}

const toolCardConfig = [
  {
    key: 0,
    src: "//img.alicdn.com/tfs/TB1GmJ2n7L0gK0jSZFAXXcA9pXa-65-64.png",
    des: "客服热线：010-95001111",
    alt: "客服热线：010-95001111"
  },
  {
    key: 1,
    src: "//img.alicdn.com/tfs/TB1ml85nYj1gK0jSZFOXXc7GpXa-62-63.png",
    des: "码上放心PC客户端",
    alt: "码上放心PC客户端",
    link:
      "http://traceent2install.mashangfangxin.com/setup.exe?spm=a21f6.8078396.0.0.46386857DjpTPs&file=setup.exe"
  },
  {
    key: 2,
    src: "//img.alicdn.com/tfs/TB1K8X1n7P2gK0jSZPxXXacQpXa-60-72.png",
    des: "码上稽查APP",
    alt: "码上稽查APP"
    // link: "//scancode.mashangfangxin.com/view/msjc"
  },
  {
    key: 3,
    src: "//img.alicdn.com/tfs/TB1mf80n1T2gK0jSZFvXXXnFXXa-56-66.png",
    des: "标准规范",
    alt: "标准规范",
    link:
      "//www.mashangfangxin.com/help/standard?spm=a21f6.8078396.0.0.190d6857VnKZ2P"
  },
  {
    key: 4,
    src: "//img.alicdn.com/tfs/TB1AbN3n1H2gK0jSZFEXXcqMpXa-64-64.png",
    des: "操作指南",
    alt: "操作指南",
    link:
      "//www.mashangfangxin.com/help/guide?spm=a21f6.8078399.0.0.76c06857tp96iS"
  }
]

const departmentConfig1 = [
  {
    key: 0,
    title: "阿里巴巴集团",
    link: "//www.alibabagroup.com/cn/global/home",
    style: {
      padding: "0 14px 0 0",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 1,
    title: "淘宝网",
    link: "//www.taobao.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 2,
    title: "天猫",
    link: "//www.tmall.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 3,
    title: "聚划算",
    link: "//ju.taobao.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 4,
    title: "全球速卖通",
    link: "//www.aliexpress.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 5,
    title: "阿里巴巴国际交易市场",
    link: "//www.alibaba.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 6,
    title: "1688",
    link: "//www.1688.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 7,
    title: "阿里妈妈",
    link: "//www.alimama.com/index.htm",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 8,
    title: "飞猪",
    link: "//www.fliggy.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 9,
    title: "阿里云计算",
    link: "//www.aliyun.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 10,
    title: "Alios",
    link: "//www.alios.cn",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 11,
    title: "阿里通信",
    link: "//aliqin.tmall.com/",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 12,
    title: "一淘",
    link: "//www.etao.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 13,
    title: "高德",
    link: "//mobile.amap.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 14,
    title: "UC",
    link: "//www.uc.cn",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  }
]
const departmentConfig2 = [
  {
    key: 15,
    title: "友盟",
    link: "//www.umeng.com",
    style: {
      padding: "0 14px 0 0",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 16,
    title: "虾米",
    link: "//www.xiami.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 17,
    title: "钉钉",
    link: "//www.dingtalk.com/?lwfrom=20150205111943449",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 18,
    title: "UC头条",
    link: "//toutiao.uc.cn",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 19,
    title: "支付宝",
    link: "//www.alipay.com",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  },
  {
    key: 20,
    title: "阿里健康",
    link: "//www.alihealth.cn",
    style: {
      padding: "0 14px",
      borderRight: "1px solid rgba(255,255,255,.5)"
    }
  }
]
export default Footer

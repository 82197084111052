import React, { FC } from "react"
import LinkCard from "../../../../components/LinkCard"
import "./index.css"

const ClassicCase: FC = () => {
  return (
    <div className="ClassicCase">
      <div className="ClassicCase-main">
        <div className="ClassicCase-title">经典案例</div>
        <div className="ClassicCase-des">
          通过以下经典案例介绍会让您更加了解“码上放心”。
        </div>
        <div className="ClassicCase-cardboard">
          {cardConfig.map((props, i) => {
            return <LinkCard {...props} />
          })}
        </div>
      </div>
    </div>
  )
}

const cardConfig = [
  {
    key: 0,
    title: "码上进社区健康全掌握赛诺菲",
    des:
      "患者只需通过手机淘宝、支付宝或阿里健康APP扫描药盒上的条形码，就可以轻松获得药品信息，掌握疾病和健康管理知识。赛诺菲也成为首个登陆“码上放心”平台，并将产品溯源和健康管理整合于一体的跨国制药企业。",
    link: ""
  },
  {
    key: 1,
    title: "雅培奶粉 产地溯源",
    des:
      "与国际知名奶粉品牌雅培合作，与雅培的追溯体系做打通，实现“多码融合”。并借助阿里大数据及海量用户，帮助品牌实现一码多用，既能防伪溯源，也能精准营销。",
    link: ""
  },
  {
    key: 2,
    title: "浙江省衢州市食品安全保障",
    des:
      "衢州市市场监管理局联合阿里健康在食品安全追溯体系方面展开深入合作，运用“码上放心追溯平台”追溯技术打通衢州生产企业和消费者的沟通渠道，实现从产品源头到终端消费者的全链条追溯。",
    link: ""
  }
]

export default ClassicCase

import { aplus_universal } from "@ali/aplus_universal"
import { formatLogData } from "./index"
import { TRACE_CODE_INTEGRATION_URL } from "../constants"

declare var window: any

/**
 * aplus 埋点
 * @param name 页面名称
 * @param url  页面url
 * @param referrer 页面来源
 * @param spmb 页面spmb位
 * @param spmUrl 页面来源  // 'spma.spmb.spmc.spmd'格式
 */
type AplusDataTypes = {
  name: string
  url: string
  spmb: string
  spmUrl: string
  postData?: object
  referrer?: string
}

const sentLog = (data: AplusDataTypes) => {
  const { name, url, referrer = "", spmb, spmUrl, postData = {} } = data
  if (!name || !url || !spmUrl || !spmb) return

  const preUrl = `${TRACE_CODE_INTEGRATION_URL}?url=`
  aplus_universal.setPageSPM("a2o12q", spmb)
  aplus_universal.enter(
    {
      pageName: name, // 必选
      pageUrl: encodeURIComponent(`${preUrl}${url}`), // 必选
      isonepage: true,
      spmUrl, // 'a.b.c.d'格式
      referrer: encodeURIComponent(`${preUrl}${referrer}`) // 可选，来源页面url
    },
    postData
  )
}

type OjectTypes = {
  [propName: string]: number | string | boolean | undefined
}
const setGoldLogFunc = (logTag: string, type: string, logData: OjectTypes) => {
  window.goldlog &&
    window.goldlog.record(logTag, type, formatLogData(logData), "GET")
}

/* global __bl */
/**
 * 默认使用阿里云 retcode 收集前端日志 & 业务数据统计使用 aplus + goldlog:TODO
 * 参考：https://help.aliyun.com/document_detail/58657.html
 */
const noop = () => {}
const armsLogger = typeof window.__bl === "object" ? window.__bl : {}
armsLogger.api = armsLogger.api || noop
armsLogger.error = armsLogger.error || noop
armsLogger.sum = armsLogger.sum || noop
armsLogger.avg = armsLogger.avg || noop
armsLogger.record = (
  api: string, // api名称
  isSuccess: boolean, // 接口是否成功
  delay: number, // 接口耗时
  msgCode: any, // 接口返回码
  params: any, // 入参
  msgDetail: any // 接口返回内容
) => {
  const limit = 1024
  let result = JSON.stringify(msgDetail)
  if (result.length > limit)
    result = `${result.slice(0, limit)}...省略了超长结果集`

  result = `Parameters are: \n${JSON.stringify(params)}\nResults are: ${result}`
  armsLogger.api(api, isSuccess, delay, msgCode, result)
}

export { sentLog, setGoldLogFunc, armsLogger }

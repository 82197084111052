type OjectTypes = {
  [propName: string]: number | string | boolean | undefined
}

// goldlog 埋点数据格式化
const formatLogData = (data: OjectTypes) => {
  let tmpData = { _g_encode: "utf-8", ...data }

  let recordData = ""
  Object.keys(tmpData).map(
    item =>
      (recordData =
        recordData + `${item}=${encodeURIComponent(tmpData[item] || "")}&`)
  )
  const len = recordData.length
  if (len > 1) {
    recordData = recordData.slice(0, len - 1)
  }

  return recordData
}

// spm 埋点数据encode
const encodeSpmLogData = (data: OjectTypes = {}) => {
  Object.keys(data).map((item: string) => {
    const value = data[item] === undefined ? "" : data[item]!
    data[item] = encodeURIComponent(value)
    return item
  })
  return data
}

// 获取url参数
interface QueryParamsType {
  [propName: string]: string
}

const getQuery = (query: string = "") => {
  query = query || window.location.search
  const index = query.indexOf("?")
  query = index > -1 ? query.slice(index + 1) : query
  let match,
    urlParams: QueryParamsType = {},
    pl = /\+/g, // Regex for replacing addition symbol with a space
    search = /([^&=]+)=?([^&]*)/g,
    decode = s => {
      return decodeURIComponent(s.replace(pl, " "))
    }
  match = search.exec(query)
  while (match) {
    urlParams[decode(match[1])] = decode(match[2]) || ""
    match = search.exec(query)
  }

  return urlParams
}

export { formatLogData, encodeSpmLogData }

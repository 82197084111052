import React, { FC, useState } from "react"
import { Carousel } from "antd"
import IntroductionCard from "../../../../components/IntroductionCard"

import "./index.css"

let ref
let sliderOver = true

const Introduction: FC = () => {
  const [currentTab, setCurrentTab] = useState(0)

  const changeTab = current => {
    setCurrentTab(current)
  }

  const item1_class = `Introduction-industry_item Item1 ${
    +currentTab === 0 ? "Introduction-industry_current" : ""
  }`

  const item2_class = `Introduction-industry_item Item2 ${
    +currentTab === 1 ? "Introduction-industry_current" : ""
  }`

  return (
    <div className="Introduction">
      <div className="Introduction-main">
        <div className="Introduction-main_title">产品简介</div>
        <div className="Introduction-main_des">
          码上放心追溯平台完全兼容“中国产品质量电子监管网”和“中国药品电子监管网”的追溯标准，能够帮助品牌商快速建立全面的产品追溯体系。
        </div>
      </div>

      <div className="Introduction-industry">
        <div className="Introduction-industry_title">
          <div
            className={item1_class}
            onClick={() => {
              if (!sliderOver) return
              changeTab(0)
              ref.slick.slickGoTo(0)
            }}
          >
            药品、疫苗行业
          </div>
          <div
            className={item2_class}
            onClick={() => {
              if (!sliderOver) return
              changeTab(1)
              ref.slick.slickGoTo(1)
            }}
          >
            工业、消费品行业
          </div>
        </div>
        <div className="Introduction-industry_shadow"></div>
        <div className="Introduction-industry_carousel">
          <Carousel
            // autoplay
            dots={false}
            afterChange={() => {
              sliderOver = true
            }}
            beforeChange={() => {
              sliderOver = false
            }}
            ref={node => {
              ref = node
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: 1218
                }}
              >
                {drugIndustryConfig.map((e, i) => {
                  const {
                    style = {},
                    src = "",
                    title = "",
                    des = "",
                    reverseTitle = "",
                    reverseDes = ""
                  } = e
                  return (
                    <IntroductionCard
                      key={i}
                      style={style}
                      src={src}
                      title={title}
                      des={des}
                      reverseTitle={reverseTitle}
                      reverseDes={reverseDes}
                    />
                  )
                })}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: 1218
                }}
              >
                {consumptionConfig.map((e, i) => {
                  const {
                    style = {},
                    src = "",
                    title = "",
                    des = "",
                    reverseTitle = "",
                    reverseDes = ""
                  } = e
                  return (
                    <IntroductionCard
                      key={i}
                      style={style}
                      src={src}
                      title={title}
                      des={des}
                      reverseTitle={reverseTitle}
                      reverseDes={reverseDes}
                    />
                  )
                })}
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

const drugIndustryConfig = [
  {
    style: { margin: "24px 0 24px 24px" },
    title: "基础追溯服务",
    src: "//img.alicdn.com/tfs/TB1A.FNnQT2gK0jSZPcXXcKkpXa-112-110.png",
    des: "满足法规要求，协助企业履行追溯主体责任。",
    reverseTitle: "基础追溯服务（基础版）",
    reverseDes:
      "为药品上市许可持有人、药品生产企业、药品经营企业和医疗机构提供基于最小销售单位的一物一码追溯体系建设，协助企业履行追溯主体责任。"
  },
  {
    style: { margin: "24px 0 24px 0" },
    title: "全程追溯服务",
    src: "//img.alicdn.com/tfs/TB1MNxOnUY1gK0jSZFCXXcwqXXa-110-110.png",
    des: "药品生命周期内数字化管理，实现产品全方位、精细化管理。",
    reverseTitle: "全程追溯服务（高级版）",
    reverseDes:
      "为上市许可持有⼈、⽣产企业提供追溯码全程流向查询、授权管理、窜货发现、数据分析等服务，赋能企业将追溯码转化为企业⽇常信息化管理⼯具。"
  },
  {
    style: { margin: "24px 0 24px 0" },
    title: "数据采集服务",
    src: "//img.alicdn.com/tfs/TB1lA0GnGL7gK0jSZFBXXXZZpXa-112-111.png",
    des: "追溯数据合法范围内开放，实现追溯码与企业管理系统打通及本地综合应用。",
    reverseTitle: "数据采集服务（D2D）",
    reverseDes:
      "为上市许可持有⼈、⽣产企业提供基于追溯码基础上数据采集、数据清理、数据落地服务， 最终实现企业追溯码数据信息的本地化综合管理。"
  },
  {
    style: { margin: "24px 24px 24px 0" },
    title: "消费者触达服务",
    src: "//img.alicdn.com/tfs/TB1AZtJnFT7gK0jSZFpXXaTkpXa-123-119.png",
    des: "以追溯码为媒介，链接企业与患者，实现患者精准管理与互动。",
    reverseTitle: "患者触达服务（M2C）",
    reverseDes:
      "为企业直接触达患者，打通线上线下全渠道服务场景，为患者提供患者教育、健康管理、会员营销等一站式服务的综合创新平台。"
  }
]

const consumptionConfig = [
  {
    style: { margin: "24px 0 24px 24px" },
    title: "源头追溯",
    src: "//img.alicdn.com/tfs/TB1w5G_nLb2gK0jSZK9XXaEgFXa-114-114.png",
    des: "供应链源头赋码，消费者扫码后能看到产品的全链路追溯。",
    reverseTitle: "源头追溯",
    reverseDes:
      "供应链源头赋码，在产品生产阶段同步采集各个环节信息，并在出入库时扫码上传相关信息。使消费者扫码后能看到产品的全链路追溯。"
  },
  {
    style: { margin: "24px 0 24px 0" },
    title: "渠道管控",
    src: "//img.alicdn.com/tfs/TB1d5DdnG61gK0jSZFlXXXDKFXa-110-104.png",
    des: "建立生产企业和经销企业的关联关系。",
    reverseTitle: "渠道管控",
    reverseDes:
      "建立生产企业与经销企业的关联关系，确认经销企业的流向，并在各级经销企业出入库时进行扫码，由此可知晓产品在各渠道的流转过程及是否属于窜货。"
  },
  {
    style: { margin: "24px 0 24px 0" },
    title: "店铺营销",
    src: "//img.alicdn.com/tfs/TB1lEO_nUz1gK0jSZLeXXb9kVXa-126-105.png",
    des: "与阿里的营销工具打通，消费者扫码后可以进店、入会、领店铺优惠券。",
    reverseTitle: "店铺营销",
    reverseDes:
      "与阿里的营销工具打通，消费者扫码后可以进店、入会、领店铺优惠券，为企业线上店铺吸引会员、拉新客、老客复购等需求提供通道。"
  },
  {
    style: { margin: "24px 24px 24px 0" },
    title: "数据分析",
    src: "//img.alicdn.com/tfs/TB12pW.nQL0gK0jSZFtXXXQCXXa-114-114.png",
    des: "以企业信息、码信息、产品信息等信息作为数据基础。",
    reverseTitle: "数据分析",
    reverseDes:
      "以企业信息、码信息、产品信息和扫码用户信息作为数据基础，可构成精准画像，帮助企业了解服务对象；以营销信息和扫码用户信息作为数据基础，可实现对用户复购、心智教育的触达。"
  }
]
export default Introduction

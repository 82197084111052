import React from "react"
import { ConfigProvider } from "antd"
import zhCN from "antd/es/locale/zh_CN"
import moment from "moment"
import Routes from "./Routes"

import "moment/locale/zh-cn"
// import "antd/dist/antd.css"
import "./common/antd.css"
import "./App.css"

moment.locale("zh-cn")

const App: React.FC = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <div className="App">
        <Routes />
      </div>
    </ConfigProvider>
  )
}

export default App

import React, { FC } from "react"
import { Carousel } from "antd"
import "./index.css"
import { relative } from "path"

const TopCarousel: FC = () => {
  return (
    <div className="Topcarousel">
      <Carousel dotPosition="bottom" dots={true}>
        <div>
          <div
            className="Topcarousel-background"
            style={{
              backgroundImage:
                "url(//img.alicdn.com/tfs/TB1JSkwn.Y1gK0jSZFMXXaWcVXa-1920-767.png)",
              backgroundColor: "rgb(0,85,250)"
            }}
          >
            <div
              style={{
                width: 1440,
                margin: "auto ",
                position: "relative",
                paddingTop: 138,
                paddingLeft: 138
              }}
            >
              <div
                style={{
                  fontFamily: "PingFangSC-Semibold",
                  fontSize: "42px",
                  color: "#fff"
                }}
              >
                码上放心溯源平台
              </div>
              <div
                style={{
                  width: "560px",
                  fontFamily: "PingFangSC-Regular",
                  fontSize: "20px",
                  color: "#fff",
                  marginTop: 27
                }}
              >
                具备丰富的软件应用生态具备丰富的软件应用生态具备丰富的软件应用生态应用生态应用
              </div>
              <a
                href="//www.mashangfangxin.com/about?spm=a21f6.8078384.0.0.7b796857Ej0ydT"
                target="_blank"
              >
                <div
                  style={{
                    background: "#fff",
                    borderRadius: "3px",
                    fontFamily: "PingFangSC-Semibold",
                    fontSize: "14px",
                    color: "#0053FF",
                    letterSpacing: "-1px",
                    width: 140,
                    height: "38px",
                    lineHeight: "38px",
                    textAlign: "center",
                    marginTop: "40px",
                    display: "inline-block"
                  }}
                >
                  了解详情
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* <div>
          <div
            className="Topcarousel-background"
            style={{
              backgroundImage:
                "url(//img.alicdn.com/tfs/TB12iIjn.Y1gK0jSZFMXXaWcVXa-1662-554.png)",
              backgroundColor: "rgb(0,85,250)"
            }}
          ></div>
        </div>
        <div>
          <div
            className="Topcarousel-background"
            style={{
              backgroundImage:
                "url(//img.alicdn.com/tfs/TB1W8QnnYH1gK0jSZFwXXc7aXXa-1440-480.png)",
              backgroundColor: "rgb(0,85,250)"
            }}
          ></div>
        </div>
        <div>
          <div
            className="Topcarousel-background"
            style={{
              backgroundImage:
                "url(//img.alicdn.com/tfs/TB19Xsnn1L2gK0jSZFmXXc7iXXa-1440-480.png)",
              backgroundColor: "rgb(0,85,250)"
            }}
          ></div>
        </div>
        <div>
          <div
            className="Topcarousel-background"
            style={{
              backgroundImage:
                "url(//img.alicdn.com/tfs/TB1W.oon1H2gK0jSZJnXXaT1FXa-1440-480.png)",
              backgroundColor: "rgb(0,85,250)"
            }}
          ></div>
        </div> */}
      </Carousel>
    </div>
  )
}

export default TopCarousel

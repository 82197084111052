import React, { FC } from "react"
import Card from "../../../../components/Card"
import "./index.css"

const Feature: FC = () => {
  return (
    <div className="Feature">
      <Card
        src="//img.alicdn.com/tfs/TB1LTEBnAL0gK0jSZFxXXXWHVXa-83-98.png"
        alt="严保合规"
        title="严保合规"
        des="紧跟政策，专家解读，转化需求"
      />
      <Card
        src="//img.alicdn.com/tfs/TB1L.7Pnp67gK0jSZPfXXahhFXa-84-88.png"
        alt="高覆盖率"
        title="高覆盖率"
        des="药品行业覆盖率90%以上"
      />
      <Card
        src="//img.alicdn.com/tfs/TB14OtpnQP2gK0jSZPxXXacQpXa-72-96.png"
        alt="安全稳定"
        title="安全稳定"
        des="稳定运行十余年，数据量超100亿"
      />
      <Card
        src="//img.alicdn.com/tfs/TB1JJ8onKT2gK0jSZFvXXXnFXXa-88-80.png"
        alt="售后强大"
        title="售后强大"
        des="联合手淘、支付宝推送消费者"
      />
    </div>
  )
}

export default Feature

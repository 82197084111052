import React, { Component } from "react"
import { sentLog } from "../../utils/log"
import { TRACE_CODE_INTEGRATION_URL } from "../../constants"
import Header from "./components/Header"
import Topcarousel from "./components/TopCarousel"
import Feature from "./components/Feature"
import Introduction from "./components/Introduction"
import Scene from "./components/Scene"
import ClassicCase from "./components/ClassicCase"
import Customer from "./components/Customer"
import Footer from "./components/Footer"

import "./index.css"

type Props = {}
type State = {}

export default class Home extends Component<Props, State> {
  readonly state: State = {}

  componentDidMount() {
    // Aplus统计
    sentLog({
      name: "商品扫码首页",
      url: TRACE_CODE_INTEGRATION_URL,
      referrer: "",
      spmb: "13730688",
      spmUrl: "a21f6.13730688.0.0",
      postData: {}
    })
  }

  render() {
    return (
      <div className="Home">
        <Header />
        <Topcarousel />
        <Feature />
        <Introduction />
        <Scene />
        <ClassicCase />
        <Customer />
        <Footer />
      </div>
    )
  }
}

import React, { FC } from "react"
import "./index.css"

type Props = {
  title: string
  des: string
  src: string
  alt: string
}

const Card: FC<Props> = props => {
  const { src, alt, title, des } = props
  return (
    <div className="Card">
      <img src={src} alt={alt} height="72" />
      <div className="Card-main">
        <div className="Card-main_title">{title}</div>
        <div className="Card-main_des">{des}</div>
      </div>
    </div>
  )
}

export default Card

import React, { FC } from "react"
import "./index.css"

const Customer: FC = () => {
  return (
    <div className="Customer">
      <div className="Customer-main">
        <div className="Customer-title">成功客户</div>
        <div className="Customer-des">各行业客户入驻“码上放心”平台</div>
        <div className="Customer-brand">
          {brandList1.map((e, i) => {
            return <img src={e.src} alt="品牌logo" style={e.style} key={i} />
          })}
        </div>
        <div className="Customer-brand">
          {brandList2.map((e, i) => {
            return <img src={e.src} alt="品牌logo" style={e.style} key={i} />
          })}
        </div>
        <div className="Customer-brand">
          {brandList3.map((e, i) => {
            return <img src={e.src} alt="品牌logo" style={e.style} key={i} />
          })}
        </div>
      </div>
    </div>
  )
}

const brandList1 = [
  {
    src: "//img.alicdn.com/tfs/TB1y2nKnFY7gK0jSZKzXXaikpXa-204-50.png",
    style: { width: "150px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1Q.PMnSf2gK0jSZFPXXXsopXa-163-88.png",
    style: { width: "120px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1hfYSnQT2gK0jSZFkXXcIQFXa-1591-320.png",
    style: { width: "150px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1RkzLnUH1gK0jSZSyXXXtlpXa-102-95.png",
    style: { width: "78px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1gFbLnLb2gK0jSZK9XXaEgFXa-135-135.png",
    style: { width: "100px" }
  }
]

const brandList2 = [
  {
    src: "//img.alicdn.com/tfs/TB1C22MnFP7gK0jSZFjXXc5aXXa-167-64.png",
    style: { width: "150px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1yL6RnRr0gK0jSZFnXXbRRXXa-184-62.png",
    style: { width: "120px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1DkYMnHr1gK0jSZFDXXb9yVXa-198-57.png",
    style: { width: "150px" }
  },

  {
    src: "//img.alicdn.com/tfs/TB1MzbQnQT2gK0jSZFkXXcIQFXa-108-109.png",
    style: { width: "78px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1nejNnKL2gK0jSZPhXXahvXXa-1772-1772.png",
    style: { width: "100px" }
  }
]

const brandList3 = [
  {
    src: "//img.alicdn.com/tfs/TB1TIB1nYj1gK0jSZFOXXc7GpXa-170-84.png",
    style: { width: "150px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1HrB2n8r0gK0jSZFnXXbRRXXa-102-100.png",
    style: { width: "120px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB1EY8Vn7L0gK0jSZFtXXXQCXXa-123-44.png",
    style: { width: "150px" }
  },

  {
    src: "//img.alicdn.com/tfs/TB1L6XUnVT7gK0jSZFpXXaTkpXa-84-84.png",
    style: { width: "78px" }
  },
  {
    src: "//img.alicdn.com/tfs/TB14up0n7T2gK0jSZPcXXcKkpXa-68-83.png",
    style: { width: "70px", margin: "0 14px" }
  }
]

export default Customer

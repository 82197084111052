import React, { FC } from "react"
import { setGoldLogFunc } from "../../../../utils/log"

import "./index.css"

const Header: FC = () => {
  return (
    <div className="Header">
      <div className="Header-content">
        <Logo />
        <Menu />
        <ActionBar />
        <Register />
      </div>
    </div>
  )
}

const Logo: FC = () => {
  return (
    <div className="Header-logo">
      <img
        src="//img.alicdn.com/tfs/TB1AI4XnEH1gK0jSZSyXXXtlpXa-185-43.png"
        alt="logo"
        height="33"
      />
    </div>
  )
}

const Menu: FC = () => {
  return (
    <div className="Header-menu">
      <span className="Header-menu-item">
        <a href="javascript:void(0)">首页</a>
      </span>
      <span className="Header-menu-item">
        <a
          href="http://www.mashangfangxin.com/brand?spm=a21f6.8078384.0.0.7b7968572fi7up"
          target="_blank"
        >
          药品疫苗
        </a>
      </span>
      <span className="Header-menu-item">
        <a href="javascript:void(0)">工业品消费品</a>
      </span>
      <span className="Header-menu-item">
        <a
          target="_blank"
          href="//www.mashangfangxin.com/help?spm=a21f6.8078388.0.0.b2e068575A4PDu"
        >
          帮助中心
        </a>
      </span>
      <span className="Header-menu-item">
        <a
          target="_blank"
          href="//www.mashangfangxin.com/about?spm=a21f6.8078396.0.0.3b126857NetrMB"
        >
          关于我们
        </a>
      </span>
    </div>
  )
}

const ActionBar: FC = () => {
  return (
    <div className="Header-action">
      <div className="Header-action-tools">
        <span
          className="Header-action-item"
          style={{ borderRight: "1px solid #fff" }}
        >
          <a
            target="_blank"
            href="http://traceent2install.mashangfangxin.com/setup.exe?spm=a21f6.8078396.0.0.46386857DjpTPs&file=setup.exe"
          >
            <img
              className="Header-action-icon"
              src="//gw.alicdn.com/tfs/TB11dzbnAY2gK0jSZFgXXc5OFXa-19-20.png"
              alt="下载"
            />
            下载安装
          </a>
        </span>
        <span
          className="Header-action-item"
          style={{ borderRight: "1px solid #fff" }}
        >
          <a
            target="_blank"
            href="//dbs.mashangfangxin.com"
            onClick={() => {
              setGoldLogFunc("/msfx_home.homepage.merchant_sys", "CLK", {})
            }}
          >
            商家后台
          </a>
        </span>
        <span className="Header-action-item">
          <a
            target="_blank"
            href="//settle.mashangfangxin.com/trace_code/enter?spm=a21f6.8078384.0.0.7db86857zz4UYw"
            onClick={() => {
              setGoldLogFunc("/msfx_home.homepage.settled_nav", "CLK", {})
            }}
          >
            <img
              className="Header-action-icon"
              src="//gw.alicdn.com/tfs/TB1qUPdnAL0gK0jSZFtXXXQCXXa-16-20.png"
              alt="入驻"
            />
            入驻
          </a>
        </span>
      </div>
      {/* <div className="Header-action-search">
        <img
          src="//img.alicdn.com/tfs/TB1GoxwnrY1gK0jSZTEXXXDQVXa-20-20.png"
          alt="搜索"
          width="20"
          height="20"
        />
        <input placeholder="追溯码查询" />
      </div> */}
    </div>
  )
}

const Register: FC = () => {
  return (
    <div className="Header-Register">
      <div className="Header-Register_img">
        <img
          src="//gw.alicdn.com/tfs/TB1oMB_n.Y1gK0jSZFMXXaWcVXa-195-196.png"
          height="116"
        />
      </div>
      {/* <div className="Header-Register_title"></div> */}
      <div className="Header-Register_des">
        药品上市许可持有人、药品生产企业、药品经营企业、医疗机构
        、工业品及消费品企业
      </div>
      <div className="Header-Register_button">
        <a
          href="//settle.mashangfangxin.com/trace_code/enter?spm=a21f6.8078384.0.0.7db86857zz4UYw"
          target="_blank"
          onClick={() => {
            setGoldLogFunc("/msfx_home.homepage.settled", "CLK", {})
          }}
        >
          申请入驻
        </a>
      </div>
    </div>
  )
}

export default Header

import React, { Suspense, lazy } from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"

import Home from "./pages/Home"

export default () => (
  <Router>
    <Route path="/" component={Home} />
    {/* <Suspense fallback={<div> </div>}>
      <Route path="/" component={lazy(() => import("./pages/Home"))} />
    </Suspense> */}
  </Router>
)

import React, { FC } from "react"
import "./index.css"

type Props = {
  des: string
  src: string
  alt: string
  link?: string
}

const ToolCard: FC<Props> = props => {
  const { src, alt, des, link = "javascript:void(0)" } = props
  const linkConfig: { href: string; target?: string } = { href: link }
  if (link !== "javascript:void(0)") linkConfig.target = "_blank"
  return (
    <div className="ToolCard">
      <a {...linkConfig}>
        <img src={src} alt={alt} width="54" height="54" />
        <div className="ToolCard-main_des">{des}</div>
      </a>
    </div>
  )
}

export default ToolCard

import React, { FC, useState } from "react"
import { Carousel } from "antd"
import SceneCard from "../../../../components/SceneCard"

import "./index.css"

let ref
let sliderOver = true

const Scene: FC = () => {
  const [currentTab, setCurrentTab] = useState(0)

  const changeTab = current => {
    setCurrentTab(current)
  }

  const item1_class =
    +currentTab === 0
      ? "Scene-industry_item Scene-industry_item1 Scene-industry_current"
      : "Scene-industry_item Scene-industry_item1"

  const item2_class =
    +currentTab === 1
      ? "Scene-industry_item Scene-industry_item2 Scene-industry_current"
      : "Scene-industry_item Scene-industry_item2"

  return (
    <div className="Scene">
      <div className="Scene-main">
        <div className="Scene-main_title">适合用场景</div>
        <div className="Scene-main_des">
          主要有药品、疫苗行业和工业品、消费品行业两大适用场景。
        </div>
      </div>

      <div className="Scene-industry">
        <div className="Scene-industry_title">
          <div
            className={item1_class}
            onClick={() => {
              if (!sliderOver) return
              changeTab(0)
              ref.slick.slickGoTo(0)
            }}
          >
            药品、疫苗行业
          </div>
          <div
            className={item2_class}
            onClick={() => {
              if (!sliderOver) return
              changeTab(1)
              ref.slick.slickGoTo(1)
            }}
          >
            工业品、消费品行业
          </div>
        </div>
        <div className="Scene-industry_shadow"></div>
        <div className="Scene-industry_carousel">
          <Carousel
            // autoplay
            dots={false}
            afterChange={() => {
              sliderOver = true
            }}
            beforeChange={() => {
              sliderOver = false
            }}
            ref={node => {
              ref = node
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: 1218
                }}
              >
                {drugIndustryConfig.map((e, i) => {
                  const {
                    style = {},
                    src = "",
                    title = "",
                    des = "",
                    reverseTitle = "",
                    reverseDes = ""
                  } = e
                  return (
                    <SceneCard
                      key={i}
                      style={style}
                      src={src}
                      title={title}
                      des={des}
                      reverseTitle={reverseTitle}
                      reverseDes={reverseDes}
                    />
                  )
                })}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: 1218
                }}
              >
                {consumptionConfig.map((e, i) => {
                  const {
                    style = {},
                    src = "",
                    title = "",
                    des = "",
                    reverseTitle = "",
                    reverseDes = ""
                  } = e
                  return (
                    <SceneCard
                      key={i}
                      style={style}
                      src={src}
                      title={title}
                      des={des}
                      reverseTitle={reverseTitle}
                      reverseDes={reverseDes}
                    />
                  )
                })}
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  )
}

const drugIndustryConfig = [
  {
    style: { margin: "24px 0 24px 24px" },
    title: "药品基础追溯",
    src: "//img.alicdn.com/tfs/TB1I6rFnQP2gK0jSZPxXXacQpXa-363-432.png",
    des: "服务体系",
    reverseTitle: "药品基础追溯",
    reverseDes:
      "依据《 中华人民共和国药品管理法》相关追溯条款要求为我国药品上市 许可持有⼈、⽣产(经营)、企业、使⽤(监管)单位提供基础追溯服务，满⾜企业基础追溯合规需求。"
  },
  {
    style: { margin: "24px 0 24px 0" },
    title: "疫苗冷链追溯",
    src: "//img.alicdn.com/tfs/TB1HGYJnND1gK0jSZFKXXcJrVXa-362-432.png",
    des: "服务体系",
    reverseTitle: "疫苗冷链追溯",
    reverseDes:
      "依据《中华人民共和国疫苗管理法》相关规定在一物一码基础上实现每只疫苗全程温度追溯，满足疫苗企业冷链追溯合规需求，为疫苗安全保驾护航。"
  },
  {
    style: { margin: "24px 0 24px 0" },
    title: "全程追溯管理",
    src: "//img.alicdn.com/tfs/TB1g9vInQT2gK0jSZPcXXcKkpXa-362-433.png",
    des: "服务体系",
    reverseTitle: "全程追溯管理",
    reverseDes:
      "在合规的前提下企业通过一物一码全程流向查询，快速定位窜货源头，最终实现品牌方的追溯全链条的管理。"
  },
  {
    style: { margin: "24px 24px 24px 0" },
    title: "患者触达",
    src: "//img.alicdn.com/tfs/TB1yWTEnFT7gK0jSZFpXXaTkpXa-363-433.png",
    des: "服务体系",
    reverseTitle: "患者触达",
    reverseDes:
      "通过赋予追溯码运营管理，帮助品牌方直接触达患者，为患者提供患者教育、健康管理、会员营销的一站式创新服务。帮助知名品牌快速实现基于药品身份证基础上的互联网+品牌、互联网+营销“创新发展”。"
  }
]

const consumptionConfig = [
  {
    style: { margin: "28px 0 28px 24px" },
    title: "品牌美誉",
    src: "//img.alicdn.com/tfs/TB18ry7nF67gK0jSZPfXXahhFXa-363-432.png",
    des: "",
    reverseTitle: "品牌美誉",
    reverseDes:
      "通过视频、图文对品牌进行宣传推广，对产品进行原产地溯源，增加消费者信任。"
  },
  {
    style: { margin: "28px 0 28px 0" },
    title: "购前引导",
    src: "//img.alicdn.com/tfs/TB1yjLDnFY7gK0jSZKzXXaikpXa-362-432.png",
    des: "",
    reverseTitle: "购前引导",
    reverseDes:
      "手机淘宝宝贝详情页打标，增加产品可信度，缩短消费者购买决策时间。"
  },
  {
    style: { margin: "28px 0 28px 0" },
    title: "复购引导",
    src: "//img.alicdn.com/tfs/TB1CzLDnFY7gK0jSZKzXXaikpXa-362-432.png",
    des: "",
    reverseTitle: "复购引导",
    reverseDes: "购买后扫码可进入企业/品牌天猫店铺领取优惠券及复购。"
  },
  {
    style: { margin: "28px 24px 28px 0" },
    title: "会员促活",
    src: "//img.alicdn.com/tfs/TB1KEYFnUT1gK0jSZFhXXaAtVXa-363-433.png",
    des: "",
    reverseTitle: "会员促活",
    reverseDes: "引导消费者成为会员，老客户激活。"
  }
]
export default Scene

import React, { FC, useState } from "react"
import "./index.css"

type Props = {
  style: object
  title: string
  src: string
  des: string
  reverseTitle: string
  reverseDes: string
}

const SceneCard: FC<Props> = props => {
  const { style, title, src, des, reverseTitle, reverseDes } = props

  return (
    <div className="SceneCard" style={style}>
      <div className="SceneCard-main">
        <div className="SceneCard-main_img">
          <img src={src} alt={title} />
        </div>
        <div className="SceneCard-main_title">{title}</div>
        <div className="SceneCard-main_des">{des}</div>
      </div>
      <div className="SceneCard-reverse">
        <div className="SceneCard-reverse_title">{reverseTitle}</div>
        <div className="SceneCard-reverse_des">{reverseDes}</div>
      </div>
    </div>
  )
}

export default SceneCard

import React, { FC } from "react"
import "./index.css"

type Props = {
  title: string
  des: string
  link: string
}

const LinkCard: FC<Props> = props => {
  const { title, des, link } = props
  return (
    <div className="LinkCard">
      <div className="LinkCard-main">
        <div className="LinkCard-main-title">{title}</div>
        <div className="LinkCard-main-des">{des}</div>
      </div>
      <div className="LinkCard-action">
        <span className="LinkCard-link">
          了解更多详情<span className='LinkCard-link_arrow'>></span>
        </span>
      </div>
    </div>
  )
}

export default LinkCard
